exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-administrador-mapa-js": () => import("./../../../src/pages/administrador/mapa.js" /* webpackChunkName: "component---src-pages-administrador-mapa-js" */),
  "component---src-pages-administrador-menu-admin-js": () => import("./../../../src/pages/administrador/menuAdmin.js" /* webpackChunkName: "component---src-pages-administrador-menu-admin-js" */),
  "component---src-pages-baixes-js": () => import("./../../../src/pages/baixes.js" /* webpackChunkName: "component---src-pages-baixes-js" */),
  "component---src-pages-destete-engreix-js": () => import("./../../../src/pages/desteteEngreix.js" /* webpackChunkName: "component---src-pages-destete-engreix-js" */),
  "component---src-pages-file-form-js": () => import("./../../../src/pages/fileForm.js" /* webpackChunkName: "component---src-pages-file-form-js" */),
  "component---src-pages-fitxes-mares-js": () => import("./../../../src/pages/fitxesMares.js" /* webpackChunkName: "component---src-pages-fitxes-mares-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-main-menu-js": () => import("./../../../src/pages/mainMenu.js" /* webpackChunkName: "component---src-pages-main-menu-js" */),
  "component---src-pages-mares-destete-js": () => import("./../../../src/pages/maresDestete.js" /* webpackChunkName: "component---src-pages-mares-destete-js" */),
  "component---src-pages-mares-escorxador-js": () => import("./../../../src/pages/maresEscorxador.js" /* webpackChunkName: "component---src-pages-mares-escorxador-js" */),
  "component---src-pages-sortida-escorxador-js": () => import("./../../../src/pages/sortidaEscorxador.js" /* webpackChunkName: "component---src-pages-sortida-escorxador-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-testfiles-js": () => import("./../../../src/pages/testfiles.js" /* webpackChunkName: "component---src-pages-testfiles-js" */),
  "component---src-pages-veterinaris-detall-js": () => import("./../../../src/pages/veterinaris/detall.js" /* webpackChunkName: "component---src-pages-veterinaris-detall-js" */),
  "component---src-pages-veterinaris-granja-veterinari-js": () => import("./../../../src/pages/veterinaris/granjaVeterinari.js" /* webpackChunkName: "component---src-pages-veterinaris-granja-veterinari-js" */),
  "component---src-pages-veterinaris-informes-js": () => import("./../../../src/pages/veterinaris/informes.js" /* webpackChunkName: "component---src-pages-veterinaris-informes-js" */),
  "component---src-pages-veterinaris-menu-visites-js": () => import("./../../../src/pages/veterinaris/menuVisites.js" /* webpackChunkName: "component---src-pages-veterinaris-menu-visites-js" */),
  "component---src-pages-veterinaris-visita-js": () => import("./../../../src/pages/veterinaris/visita.js" /* webpackChunkName: "component---src-pages-veterinaris-visita-js" */),
  "component---src-pages-veterinaris-visites-veterinaris-js": () => import("./../../../src/pages/veterinaris/visitesVeterinaris.js" /* webpackChunkName: "component---src-pages-veterinaris-visites-veterinaris-js" */)
}

